@import '../../assets/sass/app.scss';

.infobar{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    .titlebanner
    {
        height: 51px;
        width: 98.2%;
        margin-top: 12px;
        //margin-left: 14px;
        //margin-left: auto;
        padding-left: 14px;
        padding-right: 19.81px;
        background-color: #666666;
        border: 1px, solid, #979797;
        color: #CCCCCC;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        
        .title
        {
            display: flex;
            flex-direction: row;
        }
        .subjecttext
        {
            color: #FFFFFF;
            padding-left: 5px;
        }
        .titlebannericon
        {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 12px;
            align-items: center;
            //margin-left: auto;
            margin-right: 10.75px;
            .bannericon{
                flex-direction: row;
                margin-right: 19.81px;
                margin-left: auto;
                //margin-bottom: 9.5px;
                align-items: center;
            }
        }
        
    }
    .helpbar
    {
        display: flex;
            flex-direction: row;
        height: 61px;
        width: 100%;
        //padding-left: 14px;
        background-color: #D8D8D8;
        justify-content: center;
        align-items: center;
        .helpbox
        {
            display: flex;
            flex-direction: row;
            height: 48px;
            width: 99%;
            border: 1px solid #0C2340;
            border-radius: 6px;
            background-color: #FFFFFF;
            justify-content: flex-start;
            align-items: center;
            .itemI{
                //width: 100%;
                width: 24px;
                height: 24px;
                margin-left: 13px;
                margin-right: 20px;
                //margin-top: 5px;
                flex-direction: row;
                //border-color: $light-border;
                border-radius: 50px;
                background-color: $black;
                color: $white;
                font-size: 15px;
                font-weight: 600;  
                display: inline-flex;  
                align-items: center;  
                justify-content: center; 
            }
        }
    }
}