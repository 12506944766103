@import "../../assets/sass/app.scss";

.tableContainer {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 20px;
    background-color: #f7f7f7;
    border-collapse: collapse;
    td {
        padding: 7px;  
        text-align:left ;
    }
    tr {
        padding: 7px;  
        text-align:left ;
        border:1px solid #CCCCCC;
    }
    th {
        padding: 7px;
        background-color: #E6E6E6;
    }
    
    .tablehead {
        justify-content: center;
        background-color: #e6e6e6;
        color: #6244bb;
        text-align: left;
        width: 100%;
        height: 42px;
        font-size: 18px;
        font-weight: 600;
        tr {
        border:1px solid #CCCCCC;
        }
    }
    .tablebody {
        //justify-content: space-between;
        background-color: white;
        font-size: 16px;
        font-weight: 610;
        text-align: left;
        width: 100%;
        height: 43px;
        border: 1px solid #cccccc;
        padding-left: 10px; 
        .subScript {
            font-size: 14px;
            font-weight: 400;
        }       
    }
    tr:nth-child(odd) {
        background-color: white;
    }
    tr:nth-child(even) {
        background-color: #f7f7f7;
    }
    .documenttitle
    {
        //width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: $united-purple;
        font-weight: 600;
        margin-top: 5px;
        div {
            padding: 0;
        }
        svg {
            fill: $united-purple;
            margin-right: 20px;
        }  
        .manual {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            div:first-child {
                padding-right: 5px;
            }
            .arrow {
                box-sizing: border-box;
                height: 12px;
                width: 12px;
                border-style: solid;
                border-width: 0px 2px 2px 0px;
                transform: rotate(-45deg);
                margin-right: 15px;
                margin-top: 3px;
                color: $united-purple;
            }
        }      
    }
    .returndetail
            {
                margin-top: -18px;

            }
}
.clapsetitle
        {
            //width: 135.75%;
            height: 59px;
            color: $white;
            background-color: $united-blue;
            font-size: 21px;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            padding-left: 17px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            .alertarrow
            {
                width: 36%;
                color: $white;
                height: 24px;
                width: 24px;
                margin-left: auto;
                margin-right: 34.9px;
            }
            
        }
.aligncenter
{
    padding-left: 55px;
}
.tableContainerWithMargin
{
    margin-top: 18px;
}
.tablebodyevenwidth
{
    justify-content: space-between;
}
