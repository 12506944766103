@import '../../assets/sass/app.scss';

.container {
    // display: flex;
    // flex-direction: column;
    // max-width: 908px;
    // height: auto;
    // position: absolute;
    // top: 160px;
    // width: 60vw;
    width: 100%;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $united-blue;
        color: $white;
        font-size: 20px;
        height: 60px;
        .title {
            padding-left: 10px;
        }
        .copy{
            font-size: 14px;
             position: absolute;
             top: 20px;
             right: 80px;
             text-transform: capitalize;
             cursor: pointer;
         }
        .icon {
            cursor: pointer;
            //padding: 12px 12px 12px 12px;
            position: absolute;
            top: 20px;
            right: 20px;
            width: 35px;
            height: 35px;
            svg {
                fill: #FFFFFF;
            }
        }
        .close {
            cursor: pointer;
            padding: 12px 10px 0 0px;
            position: absolute;
            top: 0;
            right: 0;
            width: 35px;
            height: 35px;
        }
        .close:after {
            content: '';
            height: 35px;
            border-left: 2px solid $white;
            position: absolute;
            transform: rotate(45deg);
        }
        .close:before {
            content: '';
            height: 35px;
            border-left: 2px solid $white;
            position: absolute;
            transform: rotate(-45deg);
        }
    }
    .body {
        background-color: $white;
        height: auto;
        font-size: 18px;
        color: $charcoal;
    }
}

@media screen and (max-width: 840px) {
    .container {
        .body {
            font-size: 14px;
        }
    }
}
@media screen and (max-width: 680px) {
    .container {
        .body {
            font-size: 12px;
        }
    }
}