@import '../../assets/sass/app.scss';

.app-body {
    display: flex;
    flex-direction: row;
    // height: 100vh;
}
.page {
    margin-top: 18px;
    padding: 0 28px;
    width: 100%;
}