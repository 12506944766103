@import '../../assets/sass/app.scss';

.titlebannericon
{
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 12px;
    align-items: center;
    margin-left: auto;
    margin-right: 10.75px;
    border:1px solid red;
    .bannericon{
        display: flex;
        flex-direction: row;
        margin-right: 19.81px;
        margin-bottom: 9.5px;
        align-items: center;
    }
}
.container {
    width: 100%;
    height: 80vh;
    border:1px solid red;
    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .textual {
            margin-right: 20px;
            .info {
                font-size: 16px;
                .rev-section {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    svg {
                        fill: $united-purple;
                    }
                }
            } 
        }
        .buttons {
            display: flex;
            flex-direction: row;
            .button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                width: 90px;
                height: 70px;
                background-color: $white;
                color: $united-purple;
                font-size: 9px;
                border: 2px solid $united-purple;
                border-radius: 5px;
                .icon {
                    margin-bottom: 3px;
                    svg {
                        fill: $united-purple;
                    }
                }
                .contents {
                    padding-left: 10px;
                }
                .pins {
                    padding-left: 5px;
                }
            }
            .active {
                background-color: $united-purple;
                color: $white;
                .icon {
                    svg {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
    .firefox-warning {
        color: $failure;
        margin-bottom: 10px;
    }
    .pdf-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        color: $united-purple;
        div {
            margin-top: 200px;
        }
    }
    .contents-toggle {
        opacity: 0;
    }
    .contents-toggle:checked ~ .slideout{
        opacity: 1;
        width: 60vw;
    }
    .slideout {
        position: absolute;
        top: 160px;
        width: 0;
        left: 108px;
        max-width: 908px;
        height: auto;
        opacity: 0;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        transition-duration: .25s;
    }
    .slideout-view {
        opacity: 1;
        width: 60vw;
    }
    a {
        color: $united-purple;
        font-weight: 800;
    }
    
    @media screen and (max-width: 840px) {
        .slideout {
            top: 234px;
        }
    }
    @media screen and (max-width: 440px) {
        .slideout {
            top: 274px;
        }
        .slideout-view {
            width: 80vw;
        }
    }
}
