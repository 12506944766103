@import '../../assets/sass/app.scss';

.header {
    width: 100%;
    height: 58px;
    background-color: $united-blue;
    color: $white;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 28px;
    font-weight: 600;
    align-items: center;
    position: sticky;
    top:0;
    z-index: 50;
    .logo {
        padding-left: 24px;
    }
    .refresh-profile-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-right: 64px;
        svg {
            padding-top: 7px;
            fill: $white;
        }
        .date {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 300;
        }
        .profile {
            margin-left: 22px;
            display: flex;
            .head {
                background: $white;
                border-radius: 100%;
                width: 10px;
                height: 10px;
                margin: 3px auto 3px;
            }
            .body {
                background: $white;
                width: 18px;
                height: 15px;
                margin: auto;
                border-top-left-radius: 40%;
                border-top-right-radius: 40%;
            }
        }
        .name {
            font-size: 14px;
            font-weight: 400;
            margin-left: 8px;
        }
        .help
        {
            color: $white;
            background-color: transparent;
            font-size: 28px;
            border: 2px solid white;
            border-radius: 50px;
            width: 35px;
            height: 35px;
            margin-right: 10px;
            display: inline-flex;  
            align-items: center;  
            justify-content: center; 
            cursor: pointer;
        }
    }
}
.menu {
    background: white;
    border-radius: 8px;
    position: absolute;
    top: 55px;
    right: 0px;
    width: 200px;
    padding: 5px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease;
}
.menutriger{
    background-color:$united-blue;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    height:30px;
    width:20px
}
.menutriger:hover{
    background-color:#6244bb;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    height:30px;
    width:20px
}

.menuactive {
    background: white;
    border-radius: 8px;
    position: absolute;
    top: 55px;
    right: 0px;
    width: 200px;
    padding: 5px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(-20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.4s;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: flex;

        p {
            font-size: 16px;
            display: block;
        }

        a {
            text-decoration: none;
            font-size: 16px;
        }
    }

    .menutrigger {
        border-radius: 6px;
        background-color: #6244bb;
    }

    .signlabel {
        margin-left: 0px;
        padding-left: 15px;
        padding-top: 0px;
        color: $united-purple;
        font-size: 16px;
    cursor: pointer;
    }
 
}
.profileName{
    color:$united-blue;
}

@media screen and (max-width: 640px) {
    .title { display: none;}
}
@media screen and (max-width: 510px) {
    .date { display: none;}
    .name { display: none;}
}