@import '../../assets/sass/app.scss';

/* Custom variables */
$success-background-color: #008800;
$success-border-color: #3E7D3B;

.notification-custom-background{
    background: linear-gradient(0deg, rgba(238,238,238,0.25) 100%, #D8D8D8 100%);
    .notification-custom-success{
        background-color: $success-background-color;
        border: 1px solid $success-border-color;
    }
    .notification-custom-failure{
        background-color: $warning-color;
    }
    .notification-custom-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
        color: $white;
		height: 100px;
        .notification-custom-close{
          color: $white;
          font-size: 30px;
          cursor: pointer;
          background: none;
          border: none;	
          outline: none;
          margin-right: 17px;				
        }
		.notification-msg-section {
			// width: 250px;
			.notification-custom-title {
				height: 24px;
				// width: auto;
				color: $white;
				// font-family: "Open Sans";
				font-size: 18px;
				font-weight: bold;
			}
			.notification-custom-message {
				height: 24px;
				// width: auto;
				color: $white;
				// font-family: "Open Sans";
				font-size: 16px;
				letter-spacing: 0;
				margin: 7px 0 0 0;
			}
		}
        .notification-custom-image-container{
          width: 50px;
          .notification-custom-image{
            padding: 0 10px;
          }
        }
      }
}
