@import '../../assets/sass/app.scss';

.container {       
    margin-top: 18px; 
    .alertBox
    {
        height: 113px;        
        border-radius: 6px;
    }

    .title {
        margin-top: 5px;
        margin-left: 48px;
        //padding-left: 12px;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:left;
        align-items: center; 
    }
    .alertI
    {      
        .alertIColor
        {
            color: #0C2340;
        }  
        border-bottom: 1px solid #0C2340;
        border-right: 1px solid #0C2340;
        border-left: 1px solid #0C2340;
        border-top: 1px solid #0C2340;
    }
    .alertM
    {
        .alertMColor
        {
            color: #F46335;
        }
        
        border-bottom: 1px solid #F46335;
        border-right: 1px solid #F46335;
        border-left: 1px solid #F46335;
        border-top: 1px solid #F46335;
    }
    .alertC
    {
        .alertCColor
        {
            color: white;           
        }
        background-color: #D50032;
        border-bottom: 1px solid #D50032;
        border-right: 1px solid #D50032;
        border-left: 1px solid #D50032;
        border-top: 1px solid #D50032;
    }
    .description {
        margin-top: 5px;
        margin-left: 12px;
        font-size: 18px;
        font-weight: 400;
    }
    
    .itemI{
        //width: 100%;
        width: 24px;
        height: 24px;
        margin-left: -36px;
        margin-right: 12px;
        margin-top: 5px;
        flex-direction: row;
        //border-color: $light-border;
        border-radius: 50px;
        background-color: $black;
        color: $white;
        font-size: 15px;
        font-weight: 600;  
        display: inline-flex;  
        align-items: center;  
        justify-content: center; 
    }
    .itemM{
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 24px solid #F46335;
        margin-left: -36px;
        margin-right: 12px;
        margin-top: 5px;
        flex-direction:column;
        //border-color: $light-border;
        border-radius: 3px;
        //background-color: #F46335;
        color: $white;
        font-size: 15px;
        font-weight: 600;  
        display:flex;  
        align-items: center;  
        justify-content:last end; 
    }
    .itemC
    {
        border-bottom: 24px solid white;
        color: #D50032;
    }
    .item
    {
        font-size: 15px;
        font-weight: 600;  
    }
    .itemEX
    {
        margin-top: 4px;
    }
    .narrowBox
    {
        height: 50px;
    }
}