@import '../../assets/sass/app.scss';

.toggleIcon {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    //background-color: $united-blue;
    position: absolute;
    top:84px;
    right: 205px;
    .gridiconVisible {
        display: flex;
        align-items: center;
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 1;
        
    }
    .gridiconVisible > span,

    .gridiconVisible  > span::before {
        margin-left: 0px;
        width: 100%;
        height: 10px;
        content: '';
        background-color: $united-blue;
        top: 0px;
    }
    .gridiconVisible > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 10px;
        top: 21px;
        content: '';
        background-color: $united-blue;
        transition-duration: .25s;
        margin-left: 0px;
    }

    .gridiconInactive>span,
    .gridiconInactive > span::before {
        background-color: $united-unselected;
    }
    .gridiconInactive > span::after {
        background-color: $united-unselected;
    }

    .tileiconInVisible {
        display: grid;
        align-items: center;
        position: relative;
        font-size: 7px;
        color:$united-unselected;
        cursor: pointer;
        //float:right;
        margin-left: 13px;
        z-index: 1;
       // left: 20px;
       margin-top: 7px;
        //top: 7px;
       grid-gap: 4px;
       grid-template-columns: auto auto;
        background: $white;
      height: 24px;
      width: 24px;
      .griditem {
        background-color:$united-unselected;
      }
    }

    .tileiconActive {
        color:$united-blue;
       .griditem {
        background-color:$united-blue;
      }
    }
    
  /* .tileiconInVisible > span::after {
    fill: $united-unselected;
    //background: $united-unselected;
      content: "";
      height: 6px;
      left: -10px;
      position: absolute;
      top: 7px;
      width: 26px;
    }*/
}

