@import '../../assets/sass/app.scss';

.container {
    width: 100%;
    .tiles {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 20px;
    }
}
.statement
{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #333333;
    background-color: #F6F6F6;;
    font-weight: 400;
    //border: 1px solid #0C2340;
    border-radius: 6px;
    padding-left: 12px;
    //padding-top: 8px;
    padding-right: 14px;
    padding-bottom: 8px;
    margin-top: 18px;
    .statementtitle
    {
        color: #0C2340;
        font-size: 16px;
        font-weight: 600;
        margin-top: 8px;
    }
}
.summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: $united-blue;
    border-radius: 5px;
    color: $white;
    height: 90px;
    margin-top: 18px;
    .label {
        font-size: 13px;
    }
    .value {
        font-size: 24px;
        font-weight: 600;
    }
    padding: 0 16px;
}
.error {
    background-color: $failure;
}
.sub-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 18px;
    width: 100%;
    .title {
        color: $united-purple;
        font-size: 24px;
        font-weight: 600;
        width: 220px;
    }
    .line {
        width: 100%;
    }
}
.selection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    .item {
        margin-top: 18px;
        margin-right: 10px;
    }
}
.button-section {
    margin-top: 30px;
    input[type='button'] {
        width: 415px;
        border: 0;
        background-color: $united-purple;
        color: $white;
        outline: none;
        height: 60px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.50);
        &:disabled {
            cursor: not-allowed;
            background-color: $disabled-grey;
        }
    }
    input[type='button']:hover {
        opacity: .8;
        &:disabled {
            opacity: 1;
        }
    }
}
a {
    color: $white;
    font-weight: 800;
}

@media screen and (max-width: 615px) {
    .summary { 
        .label {
            font-size: 10px;
        }
        .value {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.skeleton-spinner-item {
    margin-top: 5px;
    width: 80%;
    background-color: #CCCCCC;
    display: inline-block;
    height: 21px;
}
.skeleton-spinner-body {
    -webkit-animation: change-opacity 2s linear infinite;
    animation: change-opacity 2s linear infinite;
    opacity: 0.3;
}
@-moz-keyframes change-opacity {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
}
@-webkit-keyframes change-opacity {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}
@keyframes change-opacity {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}