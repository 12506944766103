@import '../../assets/sass/app.scss';

.left-nav {
    width: 80px;
    background-color: $white;
    .menu-toggle {
        opacity: 0;
    }
    .menu-toggle:checked ~ .menu-btn > span {
        transform: rotate(45deg);
    }
    .menu-toggle:checked ~ .menu-btn > span::before {
        top: 0;
        transform: rotate(0);
    }
    .menu-toggle:checked ~ .menu-btn > span::after {
        top: 0;
        transform: rotate(90deg);
    }
    .menu-toggle:checked ~ .menu {
        left: 0px;
        width: 300px;
        .item-label {
            display: block;
        }
    }
    .menu-btn {
        display: flex;
        align-items: center;
        position: fixed;
        top: 70px;
        left: 20px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        margin-left: 7px;
        z-index: 75;
    }
    .menu-btn > span,
    .menu-btn > span::before,
    .menu-btn > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $charcoal;
        transition-duration: .25s;
    }
    .menu-btn > span::before {
        content: '';
        top: -8px;
    }
    .menu-btn > span::after {
        content: '';
        top: 8px;
    }
    .menu {
        display: block;
        position: fixed;
        top: 58px;
        width: 80px;
        height: 100%;
        max-height: calc(100% - 140px);
        bottom: 0;
        margin: 0;
        padding: 80px 0;
        list-style: none;
        background-color: $white;
        border-right: $light-border;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        transition-duration: .25s;
        z-index: 70;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .menu-item {
        display: flex;
        flex-direction: row;
        padding: 12px 32px;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
        transition-duration: .25s;
        cursor: pointer;

        svg {
            // display: inline-flex;
            // vertical-align: middle;
            // width: 35px;
            margin-left: -5px;
            fill: $united-unselected;
        }
    }
    .menu-item-selected {
        color: $united-purple;
        svg {
            margin-left: -5px;
            fill: $united-purple;
        }
    }
    .menu-item-unselected {
        color: $united-unselected;
    }
    .menu-item:hover {
        background-color: $united-purple;
        color: $white;
        svg {
            fill: $white;
        }
    }
    .no-hover:hover {
        background-color: white !important;
        color: $united-unselected !important;
    }
    .item-label {
        display: none;
        padding-left: 21px;
        font-size: 18px;
        font-weight: 600;
    }
    .information-section {
        margin-top: 200px;
        .information {
            background-color: $disabled-grey;
            color: $white;
            border-radius: 20px;
            padding: 0 10px;
            height: 25px;
            margin-left: -5px;
        }
    }
    .divider {
        border-bottom: solid 2px $disabled-grey;
        margin: 0 10px;
    }
    .link-item {
        margin-top: 15px;
        .help {
            color: $white;
            background-color: $united-purple;
            font-size: 18px;
            border-radius: 20px;
            padding: 2px 8px;
            width: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
        a, .psudo-link {
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;
            color: $united-purple;
            cursor: pointer;
        }
    }
    .badge{
        position: relative;
        right: 11px;
        top: -15px;
        //color: #fff;
        vertical-align: top;
    }
    .badge-warning {
        height: 14.25px;
        width: 14.25px;
        position: relative;
        right: 12px;
        top: -17px;      
        background-color: #E02020;
        border-radius: 50%;
        display: inline-flex;   
        justify-content: center;
        align-items: center;    
        font-size: 9px;
        font-weight: 600;
        color: $white;
        //vertical-align: top;
      }
}