body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.ie-msg-container {
   display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	font-size: 24px;
	font-weight: 600;
	color: #6244BB;
	margin: 0 80px;
}
.rpv-open__input-wrapper{
	display: none !important;
}
.rpv-core__popover-overlay
{display: none !important;}
button[aria-label="Print"] {
	display:none
	}
button[aria-label="More actions"] {
	display:none
	}
button[aria-label="Attachment"] {
	display:none
}

body .gc-btn--accent {
		background-color: #6244BB;
}

body .gc-accent-color {
		color: #6244BB;
}
body .gc-btn--accent:not(.gc-btn--disabled):hover {
	background-color: #6244BB !important;  
	opacity: 0.7 !important;      
}
body .gc-viewer-host .gc-viewer .gcv-menu .gc-menu__btn-container {
	background-color: #6244BB !important;
}
body .gc-viewer-host .gc-viewer .gcv-menu .gc-menu__btn-container .gc-menu__panel-toggle .gc-btn {
	background-color: #6244BB !important;
}
body .gc-menu__panel-header { color: #6244BB !important;}
body .gc-pdfthumbnails-outer .gc-pdfthumbnails-inner .thumbnail.selected .selectdecor {
	background-color: #6244BB !important;
}