@import '../../assets/sass/app.scss';

.container {
    width: 100%;
    .search-section {
        margin-top: 14px;
        margin-bottom: 30px;
        .criteria {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            color: $united-purple;
            hr {
                border-top: 1px solid $grey;
                width: 80%;
            }
        }
        .search-fields {
            display: flex;
            flex-direction: row;
            opacity: 1;
            transition-duration: .25s;
            .item {
                //margin-top: 18px;
                margin-right: 46px;
                font-size: 18px;
                font-weight: 600;
                input[type='button']{
                    color: $white;
                    background-color: $united-purple;
                    width: 299px;
                    font-size: 18px;
                    font-weight: 600;
                    padding: 20px 40px;
                    border-radius: 5px;
                    border: 0;
                    margin-top: 30px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.50);
                    cursor: pointer;
                    &:disabled {
                        cursor: not-allowed;
                        background-color: $disabled-grey;
                    }
                }
                input[type='button']:hover {
                    opacity: .8;
                    &:disabled {
                        opacity: 1;
                    }
                }
            }
            .keyword
            {
                display: flex;
                flex-direction: column;
                margin-right: 46px;
                font-size: 18px;
                font-weight: 600;
                width: 22%;
                .searchfield
                {
                    margin-top: 11px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;
                    .searchicon
                    {
                        width: 24px;
                    }
                    .icon {
                        //cursor: pointer;
                        //padding: 12px 12px 12px 12px;
                        //position: absolute;
                        //top: 20px;
                        right: 40px;
                        width: 24px;
                        height: 24px;
                        svg {
                            fill: #333333;
                        }
                    }
                    input[type='text'] {
                        height: 52px;
                        width: 200px;
                        margin-left: 12px;
                        margin-bottom: 5.5px;
                        font-size: 20px;
                        font-weight: 400;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: none;
                        outline: none;
                    }
                    
                }
                .line
                    {
                        width: 100%;
                        background-color: #333333;
                        height: 1px;
                    }
            }
            
            
        }
        .hidden {
            opacity: 0 !important;
            height: 0;
        }
    }
    .searchresult
    {
        display: flex;
        flex-direction: column;
        .clapsetitle
        {
            height: 59px;
            color: $white;
            background-color: $united-blue;
            font-size: 21px;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            padding-left: 17px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            .alertarrow
            {
                width: 36%;
                color: $white;
                height: 24px;
                width: 24px;
                margin-left: auto;
                margin-right: 34.9px;
            }
            
        }
        
    }
    .tiles {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .returndetail
    {
        margin-top: 18px;
    }
}