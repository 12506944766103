@import '../../../assets/sass/app.scss';

.search {
    width: 100%;
    max-height: 700px;
    overflow-y: auto;
    .search-types {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 23px;
        .type {
            margin-top: 32px;
            margin-right: 40px;
            .label {
                font-size: 14px;
                color: $charcoal;
            }
            input[type='text'] {
                height: 34px;
                width: 200px;
                font-size: 18px;
                border-top: none;
                border-left: none;
                border-right: none;
                outline: none;
            }
        }
        .item {
            margin-top: 32px;
            input[type='button']{
                color: $white;
                background-color: $united-purple;
                font-size: 18px;
                font-weight: 600;
                padding: 20px 40px;
                border-radius: 5px;
                border: 0;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.50);
                cursor: pointer;
                &:disabled {
                    cursor: not-allowed;
                    background-color: $disabled-grey;
                }
            }
            input[type='button']:hover {
                opacity: .8;
                &:disabled {
                    opacity: 1;
                }
            }
        }
    }
    .results {
        margin-top: 17px;
        margin-left: 23px;;
        .label {
            font-size: 16px;
            color: $charcoal;
            margin-bottom: 15px;
        }
        .searched-docs {
            display: flex;
            flex-direction: column;
            color: $united-purple;
            font-size: 16px;
            font-weight: 600;
            .doc {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 8px 0;
                cursor: pointer;
                .title-section {
                    width: 80%;
                    .subTitle {
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
                .actions {
                    display: flex;
                    flex-direction: row;
                    width: 60px;
                    padding-right: 20px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    .remove {
                        z-index: 101;
                        position: relative;
                        top: 0;
                        right: 0;
                        width: 15px;
                        height: 35px;
                    }
                    .remove:after {
                        content: "";
                        height: 35px;
                        border-left: 2px solid $united-purple;
                        position: absolute;
                        transform: rotate(45deg);
                    }
                    .remove:before {
                        content: "";
                        height: 35px;
                        border-left: 2px solid $united-purple;
                        position: absolute;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}
.navigation
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 22px;
    color: $united-purple;
    .linktitle
    {
        font-size: 14px;
        font-weight: 600;
    }
    .left-arrow {
        box-sizing: border-box;
        height: 12px;
        width: 12px;
        border-style: solid;
        border-width: 0px 2px 2px 0px;
        transform: rotate(135deg);
        margin-left: 11px;
        margin-right: 5px;
    }
    .left-arrow:hover {
        cursor: pointer;
    }
}
.helpbar
        {
            display: flex;
                flex-direction: row;
            height: 61px;
            width: 100%;
            font-size: 18px;
            font-weight: 600;
            background-color: #D8D8D8;
            justify-content: center;
            align-items: center;
            .helpbox
            {
                display: flex;
                flex-direction: row;
                height: 48px;
                width: 99%;
                border: 1px solid #0C2340;
                border-radius: 6px;
                background-color: #FFFFFF;
                justify-content: flex-start;
                align-items: center;
                .itemI{
                    //width: 100%;
                    width: 24px;
                    height: 24px;
                    margin-left: 13px;
                    margin-right: 20px;
                    //margin-top: 5px;
                    flex-direction: row;
                    //border-color: $light-border;
                    border-radius: 50px;
                    background-color: $black;
                    color: $white;
                    font-size: 15px;
                    font-weight: 600;  
                    display: inline-flex;  
                    align-items: center;  
                    justify-content: center; 
                }
            }
        }