@import '../../../assets/sass/app.scss';

.pins {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $grey;
        padding: 17px 23px;
        font-size: 16px;
        font-weight: 600;
        .clear {
            color: $united-purple;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .pinned-docs {
        display: flex;
        flex-direction: column;
        color: $united-purple;
        font-size: 16px;
        font-weight: 600;
        .doc {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 23px;
            cursor: pointer;
            .subTitle {
                font-weight: 400;
                font-size: 12px;
            }
            .actions {
                display: flex;
                flex-direction: row;
                min-width: 60px;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .remove {
                    z-index: 101;
                    position: relative;
                    top: 0;
                    right: 0;
                    width: 15px;
                    height: 35px;
                }
                .remove:after {
                    content: "";
                    height: 35px;
                    border-left: 2px solid $united-purple;
                    position: absolute;
                    transform: rotate(45deg);
                }
                .remove:before {
                    content: "";
                    height: 35px;
                    border-left: 2px solid $united-purple;
                    position: absolute;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}