@import '../../assets/sass/app.scss';

.subtitle {
    font-size: 14px;
    color: $charcoal;
}
.filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    div {
        margin-right: 10px;
    }
    input[type='text'] {
        height: 34px;
        width: 200px;
        font-size: 18px;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
    }
    input[type='button'] {
        height: 34px;
        width: 80px;
        background-color: $united-purple;
        color: $white;
        border: 0;
        border-radius: 20px;
        outline: none;
        cursor: pointer;
    }
    svg {
        fill: $light-border
    }
    .sort {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: $united-purple;
        .sorticon {
            display: flex;
            align-items: center;
            position: relative;
            width: 26px;
            height: 26px;
            cursor: pointer;
            margin-left: 7px;
            z-index: 1;
        }
        .sorticon > span,
        .sorticon > span::before,
        .sorticon > span::after {
            display: block;
            position: absolute;
            width: 50%;
            height: 2px;
            background-color: $united-purple;
            transition-duration: .25s;
            margin-left: 3px;
        }
        .sorticon > span::before {
            margin-left: -3px;
            width: 150%;
            content: '';
            top: -8px;
        }
        .sorticon > span::after {
            margin-left: 3px;
            content: '';
            top: 8px;
        }
    }
}