@import '../../assets/sass/app.scss';

.modal-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0,0,0,0.3);
    animation: fadein 400ms ease-out;
    .modal-container {
        display: flex;
        flex-direction: column;
        width: 60vw;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        .modal-header {
            background-color: $united-blue;
            height: 50px;
            color: $white;
            font-size: 24px;
            padding: 14px 0 0 20px;
            .modal-link{
                cursor: pointer;
                margin-top: -29px;
                margin-left: auto;
                margin-right: 50px;                
                font-family: Arial, Helvetica, sans-serif;
                font-weight:400px;
                width: 150px;
                height: 20px;
                
            }
            .modal-link a{
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;}
            .modal-close {
                cursor: pointer;
                padding: 14px 10px 0 0px;
                position: absolute;
                top: 0;
                right: 0;
                width: 35px;
                height: 35px;
            }
            .modal-close:after {
                content: '';
                height: 35px;
                border-left: 2px solid $white;
                position: absolute;
                transform: rotate(45deg);
            }
            .modal-close:before {
                content: '';
                height: 35px;
                border-left: 2px solid $white;
                position: absolute;
                transform: rotate(-45deg);
            }
        }
        .modal-body {
            overflow-y: auto;
            height: 65vh;
            padding: 1rem;
        }
    }
}
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}