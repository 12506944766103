@import '../../assets/sass/app.scss';

.container {
    width: 400px;
    height: 172px;
    margin-top: 18px;
    margin-right: 20px;
    border-color: $light-border;
    border-radius: 5px;
    .heading {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        background-color: $united-blue;
        color: $white;
        font-size: 20px;
        font-weight: 800;
        width: 100%;
        height: 50px;
        border-right: 2px solid $united-blue;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-left: 10px;
    }
    .lockIcon {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $light-border;
        border-right: 2px solid $light-border;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        height: 30px;
        padding-left: 10px;
        div {
            padding-right: 5px;
            padding-left: 5px;
            display: inline-block;
        }
        .divFormat {
            background-color: $united-blue; 
            color: white;         
            font-weight: normal;
            justify-content: flex-end;  
            border-radius: 5px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .footer{
        height: 102px;
        width: 100%;
        border-bottom: 1px solid $light-border;
        border-right: 1px solid $light-border;
        border-left: 1px solid $light-border;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-left: 10px;
        .bookmark {
            padding-top: 10px;
            font-size: 12px;
            padding-left: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            //position: relative;
            // cursor: pointer;
            div {
                padding-left: 0;
            }            
            .label {
                margin-left: 5px;
                color: $united-purple;
                font-size: 12px;
                cursor: pointer;
            }
            .alert{
                width: 0px;
                height: 0px;
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 24px solid #F46335;
                margin-left: auto;
                margin-right: 20px;
                flex-direction:column;
                border-radius: 3px;
                color: $white;
                font-size: 15px;
                font-weight: 600;  
                display:flex;  
                align-items: center;  
                //justify-content:right; 
                cursor: pointer;
            }
            .itemEX
            {
                margin-top: 4px;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            color: $united-purple;
            font-weight: 600;
            padding-right: 10px;
            padding-left: 0px;
            margin-top: 30px;
            div {
                padding: 0;
            }
            svg {
                fill: $united-purple;
            }
            .manual {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                div:first-child {
                    padding-right: 5px;
                }
                .arrow {
                    box-sizing: border-box;
                    height: 12px;
                    width: 12px;
                    border-style: solid;
                    border-width: 0px 2px 2px 0px;
                    transform: rotate(-45deg);
                    margin-right: 15px;
                    margin-top: 3px;
                    color: $united-purple;
                }
            }
            
        }
    }
}