@import '../../assets/sass/app.scss';

.container {
    width: 434px;
    height: 172px;
    margin-top: 18px;
    margin-right: 20px;
    border-color: $light-border;
    border-radius: 5px;
    .heading {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        background-color: $united-blue;
        color: $white;
        font-size: 21px;
        font-weight: 600;
        width: 100%;
        height: 50px;
        border-right: 2px solid $united-blue;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-left: 10px;
    }
    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #F7B500;
        border-right: 2px solid #F7B500;
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        height: 32px;
        padding-left: 10px;
        div {
            padding-right: 10px;
            padding-left: 0;
        }
    }
    .footer{
        height: 90px;
        width: 100%;
        border-bottom: 1px solid $light-border;
        border-right: 1px solid $light-border;
        border-left: 1px solid $light-border;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-left: 10px;
        .bookmark {
            //padding-top: 10px;
            font-size: 12px;
            padding-left: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 14px;
            // cursor: pointer;
            div {
                padding-left: 0;
            }
            .label::before {
                content: "Assigned to: ";
                font-weight: 700;
            }
            .label {
                //content: "Assigned to: ";
                color: $black;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;            
            color: $black;
            font-size: 12px;            
            font-weight: 600;
            padding-right: 10px;
            padding-left: 0px;
            //margin-top: 4px;
            div {
                padding: 0;
            }
            svg {
                fill: $united-purple;
            }
            .label::before{
                content: "Last Reviewed: ";
                font-weight: 700;
            }
            .label {
                color: $black;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }            
            .manual {
                display: flex;
                flex-direction: row;
                color: $united-purple;
                cursor: pointer;
                margin-top: 60px;
                div:first-child {
                    padding-right: 5px;
                }
                .arrow {
                    box-sizing: border-box;
                    height: 12px;
                    width: 12px;
                    border-style: solid;
                    border-width: 0px 2px 2px 0px;
                    transform: rotate(-45deg);
                    margin-right: 15px;
                    margin-top: 3px;
                    color: $united-purple;
                }
            }
            
            
        }
        .statusholder{
            width: 104px;
            height: 17px;
            margin-top: 60px;
            margin-right: 20px;
            //display: flex;
            flex-direction: row;
            border-color: $light-border;
            border-radius: 6px;
            //background-color: #9ADA70;
            color: $black;
            font-size: 10px;
            font-weight: 400;  
            display: inline-flex;  
            justify-content: center;
            align-items: center;   
            
        }
        .approved{background-color: #9ADA70;}
        .pending{background-color: #D8D8D8;}
        .denied{background-color: #F46868;}
    }
}