@import '../../../assets/sass/app.scss';

.select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px $charcoal;
    // width: 435px;
    height: 60px;
    color: $grey;
    margin-top: 10px;
    cursor: pointer;
    input[type='text'] {
        // width: 300px;
        height: 40px;
        color: $charcoal;
        font-weight: 400;
        font-size: 16px;
        border: none;
        outline: none;
    }
    .label {
        width: 370px;
        padding-left: 12px;
    }
    .arrow {
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        border-style: solid;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        margin-right: 15px;
        margin-top: -10px;
        color: $united-purple;
    }
}
.disabled {
    pointer-events: none;
    background-color: lightgray !important;
    color: gray;
    input[type="text"] {
        background-color: lightgray !important;
        color: gray;
    }
}
.select-small {
    width: 250px;
    input[type='text'] {
        width: 135px;
    }
}
.select-normal {
    width: 410px;
    input[type='text'] {
        width: 295px;
    }
}
@media screen and (max-width: 375px) {
    .select-normal {
        width: 365px;
        input[type='text'] {
            width: 250px;
        }
    }
    .items-normal {
        width: 217px;
    }
}

.items {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: $white;
    z-index: 2;
    max-height: 300px;
    overflow-y: auto;
    border-bottom: solid 1px $charcoal;
    cursor: pointer;
    li {
        display: flex;
        align-items: center;
        // width: 435px;
        height: 60px;
        color: $charcoal;
        border-right: solid 1px $charcoal;
        border-left: solid 1px $charcoal;
        border-bottom: solid 1px $charcoal;
        div {
            padding-left: 12px;
        }
    }
    li:hover {
        background-color: $united-purple;
        color: $white;
    }
}
.items-small {
    width: 252px;
}
.items-normal {
    width: 412px;
}
@media screen and (max-width: 375px) {
    .items-normal {
        width: 367px;
    }
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid black;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox {
    background-color: $united-purple;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkbox:after {
    content: '';
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox:after {
    display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkbox:after {
    left: 7px;
    top: 2px;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}