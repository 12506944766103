@import '../../../assets/sass/app.scss';

.pin-items-container {
    position: absolute;
    right: 400px;
     max-width: 460px;
    max-height: 300px;
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $light-border;
    border-radius: 5px;
    z-index: 100;
    padding-left: 0 !important;
    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: $charcoal;
        padding: 10px;
        .close {
            z-index: 101;
            position: relative;
            top: 0;
            right: 0;
            width: 15px;
            height: 25px;
            cursor: pointer;
        }
        .close:after {
            content: "";
            height: 25px;
            border-left: 2px solid $united-purple;
            position: absolute;
            transform: rotate(45deg);
        }
        .close:before {
            content: "";
            height: 25px;
            border-left: 2px solid $united-purple;
            position: absolute;
            transform: rotate(-45deg);
        }
    }
    .body {
        color: $united-purple;
        padding: 10px !important;
        font-size: 12px;
        cursor: pointer;
        .body-spinner-container {
            display: flex;
            align-items: center;
            width: 260px;
        }
        .doc {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 5px;
            cursor: pointer;
            .title {
                padding-right: 5px;
                font-weight: 600;
            }
            .subTitle {
                font-size: 10px;
                padding-right: 5px;
            }
        }
    }
}
.hidden {
    opacity: 0;
    height: 0;
    width: 0;
}