@import '../../../assets/sass/app.scss';

.pin-items-container {
    position: absolute;
    //top:5px;
    //right: 1350px;
    margin-left: 180px;
    margin-top: 5px;
    max-width: 516px;
    max-height: 206px;
    overflow-y: auto;
    background-color: $white;
    //border: 1px solid $light-border;
    border-radius: 5px;
    z-index: 100;
    padding-left: 0 !important;
    border-bottom: 1px solid #F46335;
    border-right: 1px solid #F46335;
    border-left: 1px solid #F46335;
    border-top: 1px solid #F46335;
    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #F46335;
        padding: 10px;
        .text
        {
            font-size: 24px;
            font-weight: 600;
            margin-right: 21px;
            margin-left: 12px;
            margin-bottom: -5px;
        }
        .close {
            z-index: 101;
            position: relative;
            top: 0;
            right: 0;
            width: 15px;
            height: 20px;
            cursor: pointer;
        }
        .close:after {
            content: "";
            height: 20px;
            border-left: 2px solid #F46335;
            position: absolute;
            transform: rotate(45deg);
        }
        .close:before {
            content: "";
            height: 20px;
            border-left: 2px solid #F46335;
            position: absolute;
            transform: rotate(-45deg);
        }
        .alert{
            width: 0px;
            height: 0px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 24px solid #F46335;
            top:0;
            left:0;
            flex-direction:column;
            border-radius: 3px;
            color: $white;
            font-size: 15px;
            font-weight: 600;  
            display:flex;  
            align-items: center;  
            justify-content: end; 
            cursor: pointer;
        }
        .itemEX
        {
            margin-top: 4px;
        }
    }
    .body {
        color:  #333333;
        padding: 10px !important;
        font-size: 18px;
        font-weight: 400;
        margin-top: -10px;
    }
}
.hidden {
    opacity: 0;
    height: 0;
    width: 0;
}