@import '../../assets/sass/app.scss';

.sort-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    border: solid 1px $grey;
    color: $charcoal;
    top: 150px;
    left: 480px;
    background-color: $white;
    border-radius: 8px;
    transition-duration: .25s;
    div {
        height: 28px;
        border-bottom: solid 1px $grey;
        margin: 0;
        margin-right: 0 !important;
        padding: 10px 0 0 10px;
    }
    div:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    div:last-child {
        border: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    div:hover {
        background-color: $united-purple;
        color: $white;
        cursor: pointer;
    }
}
.hidden {
    opacity: 0;
}