@import '../variables/colors.scss';

.arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-right: 15px;
    margin-top: -10px;
    color: $united-purple;
}
.arrow:hover {
    cursor: pointer;
}
.up-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(225deg);
    margin-right: 15px;
    margin-top: 20px;
    color: $united-purple;
}
.up-arrow:hover {
    cursor: pointer;
}
.down-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-right: 15px;
    margin-top: -10px;
    color: $united-purple;
}
.down-arrow:hover {
    cursor: pointer;
}
.right-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    margin-right: 15px;
    margin-top: 0px;
    color: $united-purple;
}
.right-arrow:hover {
    cursor: pointer;
}
.left-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(135deg);
    //margin-right: 15px;
    margin-top: 0px;
    color: $united-purple;
}
.left-arrow:hover {
    cursor: pointer;
}
.unpinned {
    font-size: 2.5em; 
    position: relative; 
    display: inline-block; 
    background-color: gray; 
    width: .2em;
    height: .3em; 
    margin: auto 1.15em .35em; 
    border-width: .08em .1em .1em; 
    border-style: solid; 
    border-color: gray white; 
    -webkit-transform: rotate(45deg); 
    transform: rotate(45deg); 
    border-radius: .1em .1em 0 0;
}
.unpinned:before {
    content: ""; 
    background-color: gray; 
    position: absolute; 
    top: 0;
    left: .08em; 
    width: 2px;
    height: .75em; 
    border-radius: 0 0 .05em; 
}
.spinner-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .spinner {
        position: relative;
        height: 50px;
        width: 50px;
        border: 2px solid transparent;
        border-top-color: $united-purple;
        border-left-color: $united-purple;
        border-radius: 50%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    .spinner::before {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        content: "";
        border: 2px solid transparent;
        border-top-color: #BA68C8;
        border-left-color: #BA68C8;
        border-radius: 50%;
        -webkit-animation: spinBack 1s linear infinite;
        animation: spinBack 1s linear infinite;
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}
@keyframes spinBack {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}
.pinned-item {
    -webkit-transform: rotate(-45deg); 
    transform: rotate(-45deg); 
}



/* width */
*::-webkit-scrollbar {
    width: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #979797;
    border-radius: 4px;
    border-left: 3.5px solid white;
    border-right: 3.5px solid white;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: rgb(98, 68, 187);
    border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}