@import '../../assets/sass/app.scss';

.simplecontainer{
    //width: 68.7%;
    //position:absolute;
    //margin-top: 45px;
    display:flex;  
    justify-content:flex-start;
    .item{
        width: 54px;
        height: 54px;
        margin-left: 13px;
        flex-direction: row;
        border-color: $light-border;
        border-radius: 50px;
        background-color: $united-unselected;
        color: $black;
        font-size: 20px;
        font-weight: 400;  
        display: inline-flex;  
        align-items: center;  
        justify-content: center; 
    }
}
.container{
    width: 100%;
    //position:absolute;
    //margin-top: 45px;
    height: 81px;
    display:flex;  
    //align-items:;  
    //justify-content: center; 
    justify-content:flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    .item{
        //width: 100%;
        width: 54px;
        height: 54px;
        margin-left: 13px;
        flex-direction: row;
        border-color: $light-border;
        border-radius: 50px;
        background-color: $united-unselected;
        color: $black;
        font-size: 20px;
        font-weight: 400;  
        display: inline-flex;  
        align-items: center;  
        justify-content: center; 
    }
    .detail{
        width: 85%;
        height: 100px;
        display:flex;
        flex-direction: column;
        padding-left: 12px;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 400; 
        .itemlabel{
            flex-direction: row;
            color: $black;             
            display: flex;  
            padding-top: 3px;  
            padding-bottom: 1px;            
        }
        .datelabel{
            height: 22px;
            flex-direction: row;
            color: $black;
            font-weight: 400;  
            display: flex;  
            justify-content:space-between; 
            align-items: center;
            color:  $united-unselected;
            font-size: 12px;
            .statusholder{
                width: 104px;
                height: 17px;
                display: flex;
                flex-direction: row;
                border-color: $light-border;
                border-radius: 6px;
                color: $black;
                font-size: 10px;
                font-weight: 400;  
                justify-content: center;
                align-items: center; 
                cursor: pointer;  
                
            }
            .approved{background-color: #9ADA70;}
            .pending{background-color: #D8D8D8;}
            .denied{background-color: #F46868;}
            .removeicon{
                width: 22px;
                height: 22px;
                //border-radius: 6px;
                //margin-left: 13px;
                //text-align: center;
                border:2px solid $united-purple;
                //border-color: $united-purple;
                border-radius: 50px;
                //background-color: $united-unselected;
                color: $black;
                font-size: 40px;
                font-weight: 400;  
                display:flex;  
                flex-direction: row;
                align-items: center;
                justify-content: center; 
                //margin:auto;
                //vertical-align: middle;
                .middleline{
                    width: 9px;
                    height: 1px;
                    background-color:  $united-purple;
                }
                cursor: pointer;
            }
            
        }
        .bottomlabel{
             color: $black; 
             display: flex;  
             flex-direction: row;
             padding-top: 12px;
           
         }
         .hyperlinklabel
         {
            color: $united-purple;
            cursor: pointer;
            text-decoration: underline;
         }
        
        
    }
    
}