@import '../../../assets/sass/app.scss';

.attachImage {
    width: 50%;
}
.img{ 
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}