@import '../../assets/sass/app.scss';

.rectangle {
    width: 100%;
    height: 22px;
    background: white;
    margin-top: -18px;
    position: sticky;
    top:58px;
  }
.container {
    width: 75%;
    //height: 80vh;
    float:left;
    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 10px;
        position: sticky;
        top:80px;
        background-color: white;
        z-index: 50;
        .textual {
            margin-right: 20px;
            .info {                
                .rev-section {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 16;
                    font-weight: 700;
                    text-align: Top;
                    svg {
                        fill: $united-purple;
                    }
                    
                }
                .statusholder{
                    width: 125px;
                    height: 25px;
                    margin-top: 4px;
                    margin-right: 20px;
                    //display: flex;
                    flex-direction: row;
                    border-color: $light-border;
                    border-radius: 6px;
                    background-color: #F7B500;
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;  
                    display: inline-flex;  
                    justify-content: center;
                    align-items: center;   
                    
                }
                .approved{background-color: #9ADA70;}
                .pending{background-color: #D8D8D8;}
                .denied{background-color: #F46868;} 
                .datelabel::before{
                    content:"Last Reviewed: ";
                    font-weight: 700;
                }
                .datelabel{
                    font-size: 16px;
                    font-weight: 400;
                }
                
            }
            
            
        }
        .buttons {
            display: flex;
            flex-direction: row;
            .button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                width: 90px;
                height: 70px;
                background-color: $white;
                color: $united-purple;
                font-size: 9px;
                border: 2px solid $united-purple;
                border-radius: 5px;
                margin-top: 15px;
                .icon {
                    margin-bottom: 3px;
                    svg {
                        fill: $united-purple;
                    }
                }
                .contents {
                    padding-left: 10px;
                }
                .pins {
                    padding-left: 5px;
                }
            }
            .active {
                background-color: $united-purple;
                color: $white;
                .icon {
                    svg {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
        .assigned{
            width: 68.7%;
            position:absolute;
            margin-top: 45px;
            display:flex;  
            justify-content:flex-end;
            .item{
                width: 54px;
                height: 54px;
                margin-left: 13px;
                flex-direction: row;
                border-color: $light-border;
                border-radius: 50px;
                background-color: $united-unselected;
                color: $black;
                font-size: 20px;
                font-weight: 400;  
                display: inline-flex;  
                align-items: center;  
                justify-content: center; 
            }
        }
        .titlebanner
    {
        height: 51px;
        width: 100%;
        margin-top: 12px;
        margin-left: auto;
        padding-left: 14px;
        background-color: #666666;
        border: 1px, solid, #979797;
        color: #CCCCCC;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
        .title
        {
            display: flex;
            flex-direction: row;
        }
        .subjecttext
        {
            color: #FFFFFF;
            padding-left: 5px;
        }
        .titlebannericon
        {
            width: 10%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 12px;
            align-items: center;
            margin-left: auto;
            margin-right: 10.75px;
            .bannericon{
                display: flex;
                flex-direction: row;
                margin-right: 19.81px;
                margin-bottom: 9.5px;
                align-items: center;
            }
        }
        
    }
    }
    
    .firefox-warning {
        color: $failure;
        margin-bottom: 10px;
    }
    
    .pdf-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        color: $united-purple;
        div {
            margin-top: 200px;
        }
    }
    .pdf-viewer{
        display: flex;
        //border: '1px solid rgba(0, 0, 0, 0.3)';
        width: 100%;
        height:60%;
        //z-Index: -100;
    }
    .contents-toggle {
        opacity: 0;
    }
    .contents-toggle:checked ~ .slideout{
        opacity: 1;
        width: 60vw;
    }
    .slideout {
        position: fixed;
        top: 172px;
        width: 0;
        left: 108px;
        max-width: 908px;
        height: auto;
        opacity: 0;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        transition-duration: .25s;
    }
    .slideout-view {
        opacity: 1;
        width: 60vw;
    }
    a {
        color: $united-purple;
        font-weight: 800;
    }
    @media screen and (max-width: 840px) {
        .slideout {
            top: 234px;
        }
    }
    @media screen and (max-width: 440px) {
        .slideout {
            top: 274px;
        }
        .slideout-view {
            width: 80vw;
        }
    }
}
.containerHTML {
    width: 75%;
    height: 80vh;
}
.assignmentpanel{
    width: 23.5%;
    height: 100%;
    float:right;
    //position: sticky;
    //top: 32px;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    margin-top: 17px;
    //margin-left: 11px;
    //margin-right: 11px;
    //flex-wrap: wrap;
    //margin-left: 27px;
    //padding-left: 27px;
    .toptitle{
        //height:40px;
        height: 80%;
        width:100%;//402px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .title{
            width: 50%;//201px;
            height: 19px;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            //background-color: $united-blue;
            //padding-left: 12.4px;
            //padding-right: 12.4px;
            padding-bottom: 21px;
            justify-content: center;
            flex-wrap: wrap;
            .activeline{
                height: 6px;
                width: 100%;//201.33px;
                background-color: $united-blue;
                //flex-wrap: wrap;
                margin-top: 13px;
            }
        }
        .reviewertitle
        {
            width: 100%;//201px;
            height: 19px;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            padding-bottom: 21px;
            justify-content: center;
            flex-wrap: wrap;
            .activeline{
                height: 6px;
                width: 100%;//201.33px;
                background-color: $united-blue;
                //flex-wrap: wrap;
                margin-top: 13px;
            }
        }
        .inactivetitle{
            font-weight: 400;
        }
        .baseline
        {
            height: 2px;
            width: 100%;
            background-color: #666666;
            //margin-top: 21px;        
        }
        .subtitle{
            width: 100%;
            height: 65vh;
            justify-content: left;
            font-size: 14px;
            font-weight: 600;
            margin-top: 27px;
            overflow-y: auto;
        }
        .subtitlereviewer{
            width: 100%;
            height: 40vh;
            justify-content: left;
            font-size: 14px;
            font-weight: 600;
            margin-top: 27px;
            overflow-y: auto;
        }
        
    }
    .bottomtitle
    {
        width:100%;//402px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:flex-start;
        font-size: 14px;
        font-weight: 600;
        //margin-bottom: 0;
        //margin-top: auto;
        .selection {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            color:#333333;
            .item {
                margin-top: 18px;
                margin-right: 10px;
            }
        }
        .assignbutton
        {
            width: 100%;//402px;
                height: 60px;
                margin-top: 13px;
                flex-direction: row;
                border-color: $light-border;
                border-radius: 5px;
                background-color: $united-purple;
                color: $white;
                font-size: 18px;
                font-weight: 600;  
                display: inline-flex;  
                align-items: center;  
                justify-content: center; 
                cursor: pointer;
        }
        .reviewnote
        {
            width: 100%;
            height: 28px;
            display: flex;
            flex-direction: row;
            justify-content: space-between; 
            margin-top: 11px;
            font-weight: 400;
            .attachment
            {
                display: flex;
                flex-direction: column;
                margin-top: 5px;
                margin-right:5px;                
                justify-content:flex-end; 
                color: $united-purple;
                .linktext
                {
                    display: flex;
                    justify-content: flex-end;
                    cursor: pointer;
                    text-decoration: underline;
                }
                .warningmessage
                {
                    text-decoration:none;
                    font-size: 10px;
                    font-style: italic;
                }
            }
        }
        .type {
            //margin-top: 32px;
            margin-right: 6px; 
            textarea[name=note]{
            resize: none;
                max-width: 402px;
        }
        }
        .item {
            width: 100%;
            margin-top: 13px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            input[type='button']{
                width: 45%;
                height: 60px;
                color: $white;
                background-color: $united-purple;
                font-size: 18px;
                font-weight: 600;
                padding: 20px 40px;
                border-radius: 5px;
                border: 0;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.50);
                cursor: pointer;
                &:disabled {
                    cursor: not-allowed;
                    background-color: $disabled-grey;
                }
            }
            input[type='button']:hover {
                opacity: .8;
                &:disabled {
                    opacity: 1;
                }
            }
        }
        .singleitem {
            width: 100%;
        /*    margin-top: 13px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;*/
            input[type='button']{
                width: 100%;}}
    }
 
}